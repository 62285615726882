<template>
  <div class="col">
    <!-- removed  {is_local} to show filter   -->

    <server-table
      :count="$store.getters['cms/totalcms']"
      :DataItems="$store.getters['cms/cms']"
      @Refresh="Refresh"
      :loading="$store.getters['cms/usersLoad']"
      class="table table-striped table-hover dataTable dtr-inline"
      :title="`${localization('Website CMS')}`"
      :filter="filter"
      @reset="filter = {}"
      edit_permission="add_cms"
    >
      <template #columns>
        <el-table-column
          prop="key"
          sortable
          :label="`${localization('Section')}`"
        ></el-table-column>
        <el-table-column
          prop="language"
          sortable
          :label="`${localization('Language')}`"
        >
          <template slot-scope="scope">
            <div>
              {{ language(languages, scope.row.language) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="removed"
          sortable
          :label="`${localization('Status')}`"
        >
          <template slot-scope="scope">
            <l-button
              outline
              round
              size="sm"
              :type="scope.row.removed == 0 ? 'success' : 'danger'"
              style="font-size: 11px; margin: 1px; padding: 2px 7px"
              @click="remove(scope.row.id, scope.row.removed)"
            >
              {{
                scope.row.removed == 0
                  ? `${localization("Active")}`
                  : `${localization("Inactive")}`
              }} </l-button
            >&nbsp;
          </template>
        </el-table-column>
        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="td-actions">
              <router-link
                v-tooltip.top-center="`${localization('Edit CMS')}`"
                :to="`/page/${scope.row.id}`"
                class="btn btn-warning btn-link btn-xs"
              >
                <i class="fa fa-edit"></i>
              </router-link>
              <!-- toggle activation button -->
              <button
                v-tooltip.top-center="
                  scope.row.removed == 0
                    ? `${localization('Delete CMS')}`
                    : `${localization('Active CMS')}`
                "
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </button>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #action>
        <!-- <div class="add_website_btn_container">
          <l-button class="" @click="edit()">
            <i class="fa fa-plus-circle mr-2"></i
            >{{ localization("Add website") }}
          </l-button>
        </div> -->
        <button
          @click="edit()"
          class="border-0 text-dark d-block "
          style="background: transparent"
        >
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add website") }}
        </button>
      </template>
      <template #filter>
        <el-row :gutter="10">
          <el-col :md="8">
            <el-select
              v-model="filter.language"
              :placeholder="`${localization('Language')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in languages"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </template>
    </server-table>

    <!-- //? start dialog  -->
    <el-dialog
      :title="`${localization('Add website')}`"
      :visible.sync="dialog"
      width="50%"
      top="4vh"
      append-to-body
      class="add_cms_dialog_user"
    >
      <el-form
        :rules="rules"
        :model="cms"
        style="width: 100%"
        ref="ruleFormAdd"
        @submit.prevent="save"
        label-position="top"
      >
        <div class="d-flex">
          <!--          <el-form-item label="Label" label-width="90px" style="width: 50%" class="mr-2">-->
          <!--            <el-input type="text" v-model="cms.key" placeholder="Please enter Label" style="width: 100%"></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item
            :label="`${localization('Language')}`"
            label-width="90px"
            style="width: 100%"
            prop="language"
          >
            <el-select
              v-model="cms.language"
              :placeholder="`${localization('Please select language')}`"
              style="width: 100%"
            >
              <el-option
                v-for="(language, i) in $store.getters['cms/languages']"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <!--        <el-form-item label="Content" label-width="90px" style="width: 100%" prop="Content">-->
        <!--          <page-->
        <!--            api-key="5134dsngirrl0brc1i314hwj587bk140g03tif8xq86wb1n7"-->
        <!--            v-model="cms.body"-->
        <!--            :init="init_en"-->
        <!--          />-->
        <!--        </el-form-item>-->
      </el-form>

      <div
        slot="footer"
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          @click="dialog = false"
          class="text-center text-capitalize save_button delete_button"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="save"
          class="text-center text-capitalize delete_button"
          >{{ localization("Save") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- //? End dialog  -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import Swal from "sweetalert2";

export default {
  name: "index",
  components: { ServerTable },
  data() {
    return {
      filter: {},
      init_en: {
        height: 260,
        mode: "none",
        menubar: false,
        dialog_type: "modal",
        importcss_append: true,
        language: "en",
        toolbar:
          "undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | fullscreen  code   | numlist bullist | forecolor backcolor | ltr rtl",
        plugins: [
          "table charmap preview fullscreen importcss textpattern  code  "
        ]
      },
      rules: {
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      dialog: false,
      cms: {}
    };
  },
  computed: {
    languages: function() {
      return this.$store.getters["cms/languages"];
    }
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("cms/GetCMS", { query: query });
    },
    edit() {
      // this.cms = item;
      this.dialog = true;
    },
    language(languages, id) {
      let language = languages.filter(el => el.id == id)[0];
      if (language) {
        return language.title;
      }
      return "-";
    },
    save() {
      this.$refs["ruleFormAdd"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("cms/add", {
              query: this.cms
            })
            .then(_ => {
              this.Refresh(null);
              this.dialog = false;
              Swal.fire({
                title: "",
                icon: "success",
                text: "Done",
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // delete cms item
    remove(id, removed) {
      Swal.fire({
        title: "",
        text:
          removed == 0
            ? `Are you sure you to delete this item?`
            : "Are you sure you to Active this item?",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "confirm!",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("cms/removeCms", {
              id: id
            })
            .then(_ => {
              this.Refresh(null);
              Swal.fire({
                title: removed == 0 ? `` : "",
                text:
                  removed == 0
                    ? "Language has been deleted."
                    : "Language has been activated.",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              });
            });
        }
      });
    }
  }
  //todo search text -- lang -- active like stra
};
</script>

<style scoped lang="scss">
.container--fluid {
  min-height: 80vh !important;
}

.tox .tox-dialog {
  z-index: 10000 !important;
}

.tox .tox-dialog--width-lg {
  z-index: 10000 !important;
}

.tox .tox-dialog-wrap {
  z-index: 10000 !important;
}

.tox .tox-dialog-wrap__backdrop {
  z-index: 10000 !important;
}

.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    background-color: #23ccef;
    border-color: #23ccef;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
.add_website_btn_container {
}
</style>

<style lang="scss">
.add_cms_dialog_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
</style>
